<template>
  <div class="mt-1">
    <registries
      :fields="fields"
      @edit="onEdit"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['infomodal', 'edit']"
      noInnerWidth
      noInputPaginator
      noPagination
    >
    </registries>
  </div>
</template>

<script>
import Registries from "@/components/tables/Registries";
import FormMixin from "@/mixins/FormMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { hasBookEntry, hasInsuranceAncillary } from "@/utils/payments";
import { mapActions } from "vuex";
import { computed } from "vue";

export default {
  mixins: [FormMixin, DetailTabAutofetchTableMixin, StorageGetterMixin],
  name: "Anagrafica",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      args: computed(() => this.args),
    };
  },
  data() {
    return {
      args: {},
      filter: this.initFilter(),
      details: null,
      REGISTRY_ID: null,
      registry: null,
      repository: "registry",
      resource: "registries",
      filterName: "filterInsurancePaymentRegistry",
      tableRef: "insurancePaymentRegistryTableRef",
      fields: [
        {
          key: "customer",
          label: this.getDictionary("customer_name"),
        },
        {
          key: "attributables.NINO",
          label: this.getDictionary("attribute_NINO_value", "registry"),
        },
        {
          key: "attributables.CREG",
          label: this.getDictionary("attribute_CREG_value", "registry"),
        },
        {
          key: "address",
          label: this.getDictionary("attribute_ADDR_value", "correspondence"),
        },
        {
          key: "address_books",
          label: this.getDictionary("address_book"),
        },
        {
          key: "attributables.CHAN",
          label: this.getDictionary("attribute_CHAN_value", "registry"),
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: { id: this.REGISTRY_ID },
      };
      return init;
    },
    onEdit(id, item) {
      // GLITCH BACK: senza abilitare questo codice, la breadcrumb mantiene Quitanzamento Ricerca come level: 2,
      // fornendo involontariamente il "torna indietro"
      // abilitandolo, la breadcrumb è fixata

      // UNCOMMENT to FIX and properly set the breadcrumbs: must recreate the path
      this.emptyQueue();
      this.notifyQueue({
        level: 0,
        path: "",
        text: "Portafoglio",
      });
      this.notifyQueue({
        level: 1,
        path: "module.PFOLIO",
        text: "Gestione Portafoglio",
      });
      this.notifyQueue({
        level: 2,
        path: "module.PFOLIO",
        text: "Clienti",
      });
      // TODO SWITCH $router push/replace when UNCOMMENT
      this.$router.replace({
        name: "registries.edit",
        params: { id: `${id}`, item: item },
      });
    },
    ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
  },
  components: {
    Registries,
  },
  beforeMount() {
    /*  */
    console.log("beforeCreate Registries");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("countries"))
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {
            console.info(
              `background data "${path}countries" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("councils"))
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {
            console.info(`background data "${path}councils" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {
            console.info(
              `background data "${path}provinces" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /*  */
    switch (true) {
      case hasBookEntry(this.dbRowData):
        this.details = this.dbRowData.payable;
        this.registry = this.details.registries[0];
        break;
      case hasInsuranceAncillary(this.dbRowData):
        this.details = this.dbRowData.payable;
        this.registry = this.details.insurance_policy.registry;
        break;
    }
    this.REGISTRY_ID = this.registry.id;
    this.$set(this.filter.byAttribute, "id", this.REGISTRY_ID);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col-md-4.border-bottom {
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
