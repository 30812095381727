<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template v-slot:head(check)="" v-if="hasChecks && !isCheckExclusive">
            <base-checkbox
              v-model="checkAll"
              name="all"
              class="check"
              @change="onSelectAllRows"
            />
          </template>
          <template #cell(check)="row" v-if="hasChecks">
            <base-checkbox
              name="check"
              class="check"
              v-model="row.item.check"
              @input="onSelectRow($event, row.index, row.item)"
            />
          </template>

          <template #cell(anomalie)="{ item }" v-if="items.length">
            <span v-html="getVirtualValue('errors', item)"></span>
          </template>

          <template #cell(diff)="{ item }" v-if="items.length">
            {{ getVirtualValue("diff", item) }}
          </template>

          <template #cell(insurer_code)="{ item }" v-if="items.length">
            {{ getVirtualValue("insurer_code", item) }}
          </template>

          <template #cell(perc)="{ item }" v-if="items.length">
            {{ getVirtualValue("perc", item) }}
          </template>

          <template #cell(ultimo)="{ item }" v-if="items.length">
            {{ getVirtualValue("ultimo", item) }}
          </template>

          <template #cell(coass)="{ item }" v-if="items.length">
            <!-- data[0].payable.insurance_ancillaries[0].insurance_policy.sharing -->
            {{ getVirtualValue("sharing", item) }}
          </template>

          <template #cell(expired_at)="{ item }" v-if="items.length">
            {{ getVirtualValue("covered_at", item) }}
          </template>

          <template #cell(payment_splitting)="{ item }" v-if="items.length">
            {{ getVirtualValue("payment_splitting_text", item) }}
          </template>

          <template #cell(number)="{ item }" v-if="items.length">
            {{ getVirtualValue("number", item) }}
          </template>

          <template #cell(branch_and_type)="{ item }" v-if="items.length">
            {{ getVirtualValue("branch_and_type", item) }}
          </template>

          <template #cell(produttore)="{ item }" v-if="items.length">
            {{ getBroker(item) }}
          </template>

          <template #cell(contraente)="{ item }" v-if="items.length">
            <span v-html="getVirtualValue('name2', item)"></span>
          </template>

          <template #cell(address_books)="{ item }" v-if="items.length">
            <span style="white-space: pre-line">{{
              getVirtualValue("contatti", item)
            }}</span>
          </template>

          <template #cell(gruppo)="{ item }" v-if="items.length">
            {{ getVirtualValue("gruppo", item) }}
            <b-icon
              icon="award"
              font-scale="1.5"
              title="È Capogruppo"
              v-if="isCoordinator(item)"
            ></b-icon>
          </template>

          <template #cell(communication)="{ item }" v-if="items.length">
            <span
              v-for="(obj, index) in getVirtualValue('communication', item)"
              :key="index"
            >
              <b-icon
                font-scale="1.5"
                :icon="obj.icon"
                :title="obj.title"
                :variant="`${obj?.red ? 'danger' : ''}`"
              ></b-icon>
              <b-icon
                v-if="obj.default"
                font-scale="1.25"
                icon="star-fill"
                variant="warning"
              ></b-icon>
              <span
                v-if="index < getVirtualValue('communication', item).length - 1"
              >
                &xrarr;
              </span>
            </span>
          </template>

          <template #cell(commento)="{ item }" v-if="items.length">
            <span v-if="item.comment">{{ item.comment }}</span>
            <!-- <span
              v-if="item.comment"
              v-b-tooltip="{
                title: item.comment,
                placement: 'left',
              }"
            >
             <b-icon
                font-scale="1.5"
                title="Commento"
                icon="chat-text"
              ></b-icon>
            </span> -->
            <span v-else>-</span>
          </template>

          <template #cell(rowSelector)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1"
                :disabled="
                  row.item.status_payment.value == 3 ||
                  row.item.status_payment.value == 4
                "
                :title="
                  'Cambia in ' +
                  (row.item.status_payment.value == 1 ||
                  row.item.status_payment.value == 2
                    ? 'valido'
                    : 'da validare')
                "
              >
                <b-icon icon="arrow-repeat"></b-icon>
              </b-button>
            </div>
          </template>

          <template #cell(info)="{ item }">
            <div class="icons-wrapper">
              <!-- Note -->
              <div
                v-if="getVirtualValue('info', item).notes"
                :role="
                  getVirtualValue('info', item).notes_counter.content
                    ? 'button'
                    : null
                "
                class="info-box"
                @click="
                  getVirtualValue('info', item).notes_counter.content
                    ? openNoteDetailModal(
                        getVirtualValue('info', item).notes.rawData
                      )
                    : null
                "
                v-b-tooltip="getVirtualValue('info', item).notes.content"
              >
                {{ getVirtualValue("info", item).notes_counter.content }}
              </div>
              <!-- Da Incassare -->
              <div
                class="info--box"
                v-if="item?.to_collect.value === 'Y' ? true : false"
              >
                <b-icon
                  font-scale="1.5"
                  title="Incassabile"
                  icon="check"
                ></b-icon>
              </div>
              <!-- Indirizzo -->
              <div class="info--box" v-if="!hasSameAddress(item)">
                <base-icon name="difference" width="18" height="18" />
              </div>
              <!-- Polizza Sostituita -->
              <div
                class="info--box"
                style="cursor: pointer"
                v-if="
                  item.payable.insurance_policy
                    ? item.payable.insurance_policy.status_policy.value === 3
                    : false
                "
                @click="
                  openReplacedPolicyDetailModal(
                    item?.payable?.insurance_policy?.id
                  )
                "
              >
                <b-icon
                  font-scale="1.5"
                  title="Polizza Sostituita"
                  icon="bell-fill"
                ></b-icon>
              </div>
              <!-- Forzatura Stato -->
              <div class="info--box" v-if="item.modified.value === 'Y'">
                <b-icon
                  font-scale="1.5"
                  title="Forzatura Stato"
                  icon="flag-fill"
                  variant="danger"
                ></b-icon>
              </div>
              <!-- Sepa/Rid -->
              <div
                class="info--box"
                v-if="
                  item?.payable?.insurance_policy
                    ? item?.payable?.insurance_policy?.attributables.IBAN ===
                      'Y'
                    : false
                "
              >
                <base-icon name="sepa" width="20" height="20" />
              </div>
              <!-- Convenzione -->
              <div
                class="info--box"
                v-if="
                  item?.payable?.insurance_policy
                    ? item?.payable?.insurance_policy?.attributables.CONV
                    : false
                "
              >
                <b-icon
                  font-scale="1.5"
                  :title="item?.payable?.insurance_policy?.attributables.CONV"
                  icon="bag"
                ></b-icon>
              </div>
              <!-- Premio Modificato-->
              <div
                v-if="getVirtualValue('info', item).premium"
                class="info-box"
                v-b-tooltip="{
                  title:
                    '<b>' +
                    getVirtualValue('info', item).premium.header +
                    '</b><br/>' +
                    getVirtualValue('info', item).premium.content,
                  placement: 'left',
                  html: true,
                }"
              >
                <b-icon icon="currency-euro" variant="danger"></b-icon>
              </div>
              <!-- Quietanze inviate -->
              <div v-if="item?.sent.value === 'Y'" class="info--box">
                <b-icon
                  icon="telegram"
                  font-scale="1.5"
                  title="Quietanza inviata con successo"
                  variant="success"
                ></b-icon>
              </div>
            </div>
          </template>
          <!-- use @change not @input in order to trigger only user interaction -->
          <template #cell(selezionato)="{ item }">
            <template v-if="item?.status_payment?.value === 0">
              <input type="hidden" :value="calculateItemSelected(item)" />
              <base-icon
                name="loading"
                width="15"
                height="15"
                class="hide-it"
                style="position: relative; top: -2px"
              />
              <base-checkbox
                :ref="`cbx-${item.id}`"
                name="selected"
                class="check"
                v-model="item.selected.value"
                chk_val="Y"
                unchk_val="N"
                @change="onSelezionato($event, item)"
              />
            </template>
          </template>

          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="
                  actions.includes('infomodal') &&
                  row.item?.payment_type?.value === 2
                "
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2" v-for="(value, key) in row.item" :key="key">
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <div v-if="hasChecksButtonGroup">
        <b-button-group
          size="md"
          class=""
          style="display: flex; align-items: center; max-width: max-content"
        >
          <b-button
            variant="transparent"
            class="text-decoration-none"
            @click="spuntaTutte()"
          >
            <b-icon icon="check" class="check-icon"></b-icon>Spunta tutte
          </b-button>
          <b-button
            variant="transparent"
            class="text-decoration-none"
            @click="togliSpuntaTutte()"
          >
            <b-icon icon="x" class="cross-icon"></b-icon>Togli spunte a tutte
          </b-button>
          <p class="select-all" style="margin: 0; padding: 5px 10px">
            Selezionate: {{ cntr }}
          </p>
        </b-button-group>
      </div>
      <b-row v-if="!noPagination">
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="lg"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  :dbRowData="dbRowData"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import General from "@/components/insurance-payments/details/General.vue";
import Policy from "@/components/insurance-payments/details/Policy.vue";
import Anagrafica from "@/components/insurance-payments/details/Anagrafica.vue";
import Notes from "@/components/insurance-payments/details/Notes.vue";
import Premio from "@/components/insurance-payments/details/Premio.vue";
import Comment from "@/components/insurance-payments/details/Comment.vue";
import Collect from "@/components/insurance-payments/details/Collect.vue";
import { getVirtualValue, hasSameAddress } from "@/utils/payments";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toInfoData } from "@/utils/transforms";

export default {
  name: "InsurancePaymentsTable",
  extends: template,
  data() {
    return {
      counterSelezionate: 0,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Anagrafica", text: "Anagrafica" },
        { name: "Policy", text: "Polizza" },
        { name: "Notes", text: "Note" },
        { name: "Comment", text: "Commento" },
        { name: "Premio", text: "Modifica Premio" },
        { name: "Collect", text: "Incassabile" },
      ],
      selected: "General", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
      dbRowData: null, // used to pass the actual record to tabs
    };
  },
  components: {
    BaseIcon,
    BaseInputPagination,
    BaseCheckbox,
    General,
    Anagrafica,
    Policy,
    Notes,
    Comment,
    Premio,
    Collect,
  },
  computed: {
    isChecked() {
      return (item) => {
        return item.selezionato;
      };
    },
    cntr() {
      return this.counterSelezionate;
    },
  },
  mounted() {},
  props: {
    hasChecksButtonGroup: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    toInfoData,
    getVirtualValue,
    hasSameAddress,
    calculateItemSelected(item) {
      if (!item.selected) item["selected"] = { text: "", value: null };
      item.selected.value = item.users.length ? "Y" : "N";
      item.selected.text = item.users.length ? "Si" : "No";
    },
    loadSelectedCounter() {
      const userId = this.$store.state.auth.user.id;
      let queryString = `byUser[id]=${userId}&count`;
      let Repo = RepositoryFactory.get("insurance_payment");
      return Repo.index(queryString)
        .then((response) => {
          this.counterSelezionate = response.data.data.count;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSelezionato(value, item) {
      const checkboxElement = this?.$refs?.[`cbx-${item.id}`]?.$el;
      if (
        checkboxElement &&
        checkboxElement.previousElementSibling &&
        checkboxElement.previousElementSibling.matches(".hide-it")
      ) {
        checkboxElement.previousElementSibling.classList.remove("hide-it");
        checkboxElement.classList.add("hide-it");
      }
      // console.debug("onSelezionato");
      console.debug(value, item);
      /* */
      if (!item.selected) item["selected"] = { text: "", value: null };
      item.selected.value = value === "Y" ? "Y" : "N";
      item.selected.text = value === "Y" ? "Si" : "No";
      /* */
      this.$emit("selezionato", item, checkboxElement);
    },
    spuntaTutte() {
      this.$emit("spunta");
    },
    togliSpuntaTutte() {
      this.$emit("toglispunta");
    },
    info(item, index, button) {
      // TODO: check if it's possible perform async calls here!!!
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      // this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.$bvModal.show(this.infoModal.id, button);
    },
    getBroker(item) {
      let brokerId = null;
      if (item.payable_type.includes("BookEntry")) {
        brokerId = item?.payable?.brokers[0]?.id;
      } else {
        brokerId = item?.payable?.insurance_policy?.broker_id;
      }
      if (brokerId) {
        const broker = this.$store.state.auth.salesmen.find(
          (salesman) => salesman.id === brokerId
        );
        return broker ? toInfoData(broker, "broker", 0) : "";
      }
      return "";
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    openReplacedPolicyDetailModal(policy_id) {
      this.$emit("open_replaced_policy", policy_id);
    },
    isCoordinator(item) {
      const registry =
        item.payable?.insurance_policy?.registry ??
        item.payable?.registries?.[0];

      if (!registry) {
        return false;
      }

      return registry.registry_groups.find(
        (registry_group) => registry_group.pivot.is_coordinator.value === "Y"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
.select-all {
  font-size: 12px;
  margin-top: 7px;
  margin-left: 8px;
}
.check-icon {
  color: green;
}
.cross-icon {
  color: red;
}
.text-decoration-none {
  font-size: 12px;
}
:deep(table .form-group) {
  margin-bottom: 0rem !important;
}
:deep(input[type="checkbox"]) {
  cursor: pointer;
}
:deep(.hide-it) {
  display: none;
}
:deep(.icons-wrapper) {
  display: grid;
  grid-template-columns: repeat(2, 24px);
  column-gap: 2px;
  row-gap: 5px;
}
</style>
